<template>
  <v-container class="app-page">
    <v-row>
      <v-col>
        <v-row v-if="!loading && records.length==0">
          <v-col>
            <v-card class="list-item d-flex justify-start no-hay-informacion">
              <div class="list-content">
                <div class="nombre text-center">No hay informacion que mostrar</div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      
        <v-row v-else>
          <v-col>
            <v-card v-for="item in records" class="list-item d-flex justify-start" :key="item.idDetaCalc" @click="navigate(item)">
              <div class="pua">
                <svg
                  class="list-deseleccionada"
                  xmlns="http://www.w3.org/2000/svg"
                  width="35"
                  height="33"
                  viewBox="0 0 35 33"
                  fill="none"
                >
                  <path
                    d="M8.31273 0.126751C1.97281 1.49578 -0.104376 8.87434 0.00399863 16.484C0.00399896 24.0937 1.97281 31.4723 8.31273 32.8413C8.9991 32.948 10.0467 33.0724 10.8595 32.948C13.4063 32.7169 16.1699 31.4723 18.2471 30.6722C23.7922 28.2897 28.633 24.876 32.6609 20.4489C33.4737 19.5421 34.6297 18.5109 34.9729 17.0352C34.9729 16.9285 35.0813 16.3596 34.8645 15.6662C34.3949 14.4216 33.4737 13.3904 32.679 12.6081C28.633 8.18093 23.7922 4.76724 18.2651 2.38476C16.188 1.478 13.4244 0.340106 10.8776 0.10897C10.0467 -0.086606 8.9991 0.0200726 8.31273 0.126751Z"
                    fill="#DADEE5"
                    style="&#10;"
                  />
                </svg>
              </div>
              <div class="list-content">
                <div class="nombre">Informe para DGI de {{ item.MesDeLiq | gnsMonth }}</div>
                <div class="categoria">{{ item.MesDeLiq | gnsYear }}</div>
              </div>
              <div class="download">
                <img src="@/assets/irpf/download.svg"/>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getInformes } from '@/api/core'
import { gnsSmallDate, gnsMonth, gnsYear } from '@/filters/index'

export default {
  name: "dgi",
  data() {
    return {
      loading: false,
      records: []
    };
  },

  created() {},

  watch: {},

  computed: {},

  mounted() {
    this.$store.commit("setNavigationDrawer", false)
    this.$store.commit('setBackButton', false)
    this.$store.commit('setToolbarTitle', 'Informe para DGI')
    this.$store.commit('stopProcessing')   
    this.getList() 
  },

  filters: {
    gnsDate: function (value) {
      return gnsSmallDate(value)
    }, 

    gnsMonth: function (value) {
      return gnsMonth(value)
    },

    gnsYear: function (value) {
      return gnsYear(value)
    }
  },

  methods: {
    async getList() {
      this.loading = true

      const data = await getInformes()

      if(data && data.length > 0) {
          this.records = this.records.concat(data)
      }

      this.loading = false
    },

    navigate(item) {
      this.$router.push({ name: 'detalle_calculo', params: {id: item.idDetaCalc} })
    }
  }
};
</script>

<style>
.no-hay-informacion:hover{
  transform: scale(1.0);
  cursor:unset;
}
</style>
